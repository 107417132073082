<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
       <v-flex xs3 text-left>
              <span class="mainHeader">SALES PAYMENT</span>
            </v-flex>
      <v-flex xs12 py-6 text-left>
        <v-card
          tile
        
          :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
          :flat="$route.name == 'Dashboard' ? true : false"
        >
          <v-layout px-4 py-4>
            <v-flex xs12>
              <v-card outlined class="pa-4">
                <v-layout wrap>
                  <v-flex xs12 v-if="itemz">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left subhed"><b>S.No.</b></th>
                            <th class="text-left subhed"><b>Design</b></th>
                            <th class="text-left subhed"><b>Count</b></th>
                            <th class="text-left subhed"><b>Gross.Wt </b></th>
                            <th class="text-left subhed"><b>Item/Wt</b></th>
                            <th class="text-left subhed"><b>Cost</b></th>
                            <th class="text-left subhed"><b>StonePrice</b></th>
                            <th class="text-left subhed"><b>Stone.Wt</b></th>
                            <th class="text-left subhed"><b>Net.Wt</b></th>
                            <th class="text-left subhed"><b>Total</b></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(value, i) in itemz"
                            :key="i"
                            class="table"
                          >
                            <!-- <td>{{ i + 1 }}</td> -->
                            <td>
                              <span>
                                {{ i }}
                              </span>
                            </td>
                            <td>
                              <span v-if="value.designName">{{
                                value.designName
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.count"> {{ value.count }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.grossWeight">{{
                                value.grossWeight
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.itemPerWeight">{{
                                value.itemPerWeight
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.cost">{{ value.cost }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.stonePrice">{{
                                value.stonePrice
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.stoneWeight">{{
                                value.stoneWeight
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.netWeight">{{
                                value.netWeight
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.totalAmount">{{
                                value.totalAmount
                              }}</span>
                              <span v-else>-</span>
                            </td>

                            <td>
                              <v-icon
                                size="18"
                                style="cursor: pointer"
                                @click="(dialoge = true), (removeitemIndex = i)"
                                >mdi-delete-outline</v-icon
                              >
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-flex>
                </v-layout>
                <v-layout wrap v-if="itemz">
                  <v-flex mt-3 v-if="itemz.length > 0">
                    <v-btn dark color="#3F053C" block @click="submit()"
                      >Save</v-btn
                    >
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs6>
              <v-layout px-4 py-4 wrap>
                <v-flex xs12>
                  <span class="subhed2">Payment Type</span>
                </v-flex>
                <v-flex xs4 pa-2>
                  <v-card color="#EEEEEE" class="px-4 py-2" outlined>
                    <v-layout wrap justify-center>
                      <v-flex xs12 align-self-center>
                        <v-checkbox
                          hide-details
                          v-model="paymentTypes"
                          label="Cash"
                          value="Cash"
                          @change="choosetype()"
                        ></v-checkbox>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
                <v-flex xs4 pa-2>
                  <v-card color="#EEEEEE" class="px-4 py-2" outlined>
                    <v-checkbox
                      hide-details
                      v-model="paymentTypes"
                      label="Thankam"
                      value="Thankam"
                      @change="choosetype()"
                    ></v-checkbox>
                  </v-card>
                </v-flex>
              </v-layout>

              <v-layout wrap v-if="checkvalue == true">
                <v-flex xs6 pa-4>
                  <span>Thankam Code</span>
                  <v-text-field
                    autofocus
                    label="Thankam Code"
                    outlined
                    disabled
                    dense
                    hide-details
                  ></v-text-field>
                    <!-- v-model="Details.bill" -->
                </v-flex>
                <v-flex xs6 pa-4>
                  <span>Batch Code</span>
                  <v-text-field
                    autofocus
                    label="BoardingRate"
                    v-model="thankamDetails.boardingRate"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-flex>

                <v-flex xs6 pa-4>
                  <span>Gold rate</span>
                  <v-text-field
                    autofocus
                    label="thankamWeight"
                    v-model="thankamDetails.thankamWeight"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs6 pa-4>
                  <span>Gold weight</span>
                  <v-text-field
                    autofocus
                    label="batchCode"
                    v-model="thankamDetails.batchCode"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex><v-btn class="buttons1" @click="finalSubmit()">final</v-btn></v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs6 px-4 py-4 align-self-end>
              <v-layout wrap justify-end class="pb-4">
                <v-flex xs9 class="subhed2" text-right>
                  Total Weight (gm) :
                </v-flex>
                <v-flex xs3 class="subhed3" text-center
                  >
                  <!-- <span v-if="totalGrossWeight">{{ totalGrossWeight }}</span>
                  <span v-else>-</span> -->
                  </v-flex
                >
              </v-layout>
              <v-layout wrap justify-end class="pb-4">
                <v-flex xs9 class="subhed2" text-right>Making Charge : </v-flex>
                <v-flex xs3 class="subhed3" text-center
                  >
                  <!-- <span v-if="totalMakingCharge">{{ totalMakingCharge }}</span>
                  <span v-else>-</span> -->
                  </v-flex
                >
              </v-layout>
              <v-layout wrap justify-end class="pb-4">
                <v-flex xs9 class="subhed2" text-right
                  >Total Amount to be paid :
                </v-flex>
                <v-flex xs3 class="subhed3" text-center
                  >
                  <!-- <span v-if="totalPurchaseCost">{{ totalPurchaseCost }}</span>
                  <span v-else>-</span> -->
                  </v-flex
                >
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 2000,
      msg: null,
   itemz:[],
      paymentTypes: [],
      checkvalue: false,
      purchaseId: null,
      payment:[ {
        paymentType:null,
        payAmount:null,}
      ],
        thankamDetails:[{
        thankamId:"63d3a2d42d13b00bbea3a98f",
        boardingRate:null,
        thankamWeight:null,
        batchCode:null,}
      ],
    };
  },
  methods: {
     validateInput() {
      if (!this.email) {
        this.msg = "Please provide email";
        this.showsnackbar = true;
        return;
      } else if (!this.name) {
        this.msg = "Please provide name";
        this.showsnackbar = true;
        return;
      } else if (!this.phone) {
        this.msg = "Please provide phone";
        this.showsnackbar = true;
        return;
      } else if (!this.username) {
        this.msg = "Please provide username";
        this.showsnackbar = true;
        return;
      }  else if (!this.address) {
        this.msg = "Please provide address";
        this.showsnackbar = true;
        return;
      }  else {
        this.addAdmin();
      }
    },
      choosetype() {
      console.log(this.paymentTypes, "array");
      if (this.paymentTypes.includes("Thankam")) {
        this.checkvalue = true;
      } else {
        this.checkvalue = false;
      }
    },
      addAdmin() {
      
      axios({
        url: "/user/jewelleryregister",
        method: "POST",
        data: {
          username:this.username,
          email:this.email,
          jewelleryName: this.name,
          phone: this.phone,
          location: this.address,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status==true) {
            this.msg =response.data.msg;
            this.showsnackbar = true;
          this.email=null;
          this.phone=null;
          this.name=null;
          this.address=null;
          this.username=null;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
      
     
    },
  },
};
</script>
